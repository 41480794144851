import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-blog-datails',
  templateUrl: './blog-datails.component.html',
  styleUrls: ['./blog-datails.component.scss']
})
export class BlogDatailsComponent implements OnInit {
id=''
  show: boolean=false;
  blog_details: any;
  constructor(private route:ActivatedRoute , private blog:AppService ) {
   const  number= this.route.snapshot.params.blog_id.match(/\d+/);
this.id=number[0]
   }

  ngOnInit(): void {

this.getProjects()
  }
  getProjects(){

    this.blog.blog_details(this.id).pipe(map(res=>res['data'])).subscribe(project=>{



      this.blog_details = project;
      console.log( this.blog_details);

      this.show=true;

    })
  }
}

<section class="blog_details" >
  <div class="container">
    <div class="row" *ngIf="!show">
      <div
        class="col-lg-12 project"
        *ngFor="let item of [].constructor(1); let i = index"
      >
        <div class="ph-item" style="border: none">
          <div class="ph-col-12">
            <div class="ph-picture" style="min-height: 500px"></div>
            <div class="ph-row">
              <div class="ph-col-2 empty big"></div>
              <div class="ph-col-10 big"></div>
              <div class="ph-col-8 empty"></div>
              <div class="ph-col-4"></div>
              <div class="ph-col-6 empty"></div>
              <div class="ph-col-6"></div>

              <div class="ph-col-12"></div>
              <div class="ph-col-12"></div>
              <div class="ph-col-12"></div>
              <div class="ph-col-12"></div>
              <div class="ph-col-12"></div>

              <div class="ph-col-12"></div>
              <div class="ph-col-12"></div>
              <div class="ph-col-12"></div>
              <div class="ph-col-12"></div>
              <div class="ph-col-12"></div>
              <div class="ph-col-12"></div>
              <div class="ph-col-12"></div>
              <div class="ph-col-12"></div>
              <div class="ph-col-12"></div>
              <div class="ph-col-12"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="show">
      <div class="col-lg-12 project" [innerHTML]="blog_details?.content"></div>
    </div>
  </div>
</section>

<section class="blog_details" >
  <div class=" words">
    <div class="row m-0">
        <div class="col-md-6 text-center m-auto">
            <h1 class="mb-5 font-weight-bold" data-aos="fade-up">
              الوظائف
            </h1>
            <p data-aos="zoom-out-down" class="mb-5 font-weight-bold">

              نحن نؤمن بشدة بأن الشركات العظيمة تصنعها أشخاص عظماء. ولهذا السبب نساعد آلاف الشركات على جذب المزيد من المرشحين وإدارتهم بكفاءة أكبر من خلال بناء برامج قوية وممتعة وسهلة الاستخدام.          </div>
    </div>
</div>
  <div class="container">
    <div class="row mt-5" *ngIf="!show">
      <div
        class="col-lg-8 m-auto project"
        *ngFor="let item of [].constructor(1); let i = index"
      >
        <div class="ph-item" style="border: none">
          <div class="ph-col-12">
            <div class="ph-picture" style="min-height: 200px"></div>
            <div class="ph-row">
              <div class="ph-col-2 empty big"></div>
              <div class="ph-col-10 big"></div>
              <div class="ph-col-8 empty"></div>
              <div class="ph-col-4"></div>
              <div class="ph-col-6 empty"></div>
              <div class="ph-col-6"></div>

              <div class="ph-col-12"></div>
              <div class="ph-col-12"></div>
              <div class="ph-col-12"></div>
              <div class="ph-col-12"></div>
              <div class="ph-col-12"></div>

              <div class="ph-col-12"></div>
              <div class="ph-col-12"></div>
              <div class="ph-col-12"></div>
              <div class="ph-col-12"></div>
              <div class="ph-col-12"></div>
              <div class="ph-col-12"></div>
              <div class="ph-col-12"></div>
              <div class="ph-col-12"></div>
              <div class="ph-col-12"></div>
              <div class="ph-col-12"></div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
  <div class="content"  *ngIf="show">
  <div class="container">
    <div class="row mt-5">
<div class="col-md-9 m-auto">
  <div class="card mt-5" style="text-align: end;" data-aos="fade-up">
    <div class="title text-center my-3 p-3">
      <h5
        class="card-title mt-3 name font-weight-bold text-capitalize"
      >
        {{ blog_details.title }}
      </h5>
      <p class="card-text mb-2  px-3"  >
{{blog_details?.location}} - {{blog_details.type}}
      </p>
    </div>
    <div  class="mb-2">
      <h5
        class="card-title mt-3 name font-weight-bold text-capitalize"
      >
       : Job Summary
      </h5>
      <p class="card-text mb-2  px-3" [innerHTML]="blog_details?.summary"  >

      </p>
    </div>
    <div  class="mb-2">
      <h5
        class="card-title mt-3 name font-weight-bold text-capitalize"
      >
       : Key Responsibilities
      </h5>
      <p class="card-text mb-2  px-3" [innerHTML]="blog_details?.responsibilities"  >

      </p>
    </div>
    <div  class="mb-2">
      <h5
        class="card-title mt-3 name font-weight-bold text-capitalize"
      >
       : Qualifications
      </h5>
      <p class="card-text mb-2  px-3  p-2" [innerHTML]="blog_details?.qualifications"  >

      </p>
    </div>
    <div  class="mb-2">
      <h5
        class="card-title mt-3 name font-weight-bold text-capitalize"
      >
       : Preferred Skills
      </h5>
      <p class="card-text mb-2  px-3" [innerHTML]="blog_details?.skills"  >



      </p>
    </div>
    <div  class="mb-2">
      <h5
        class="card-title mt-3 name font-weight-bold text-capitalize"
      >
      Upload Your CV
      </h5>
      <div
      *ngIf="files.length == 0 "
      class="upload-box"
      (dragover)="onDragOver($event)"
      (dragleave)="onDragLeave($event)"
      (drop)="onDrop($event)">
     <svg style="display:block ; margin:auto" width="30" height="25" viewBox="0 0 30 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.7505 6.04406C22.3183 4.31643 21.3214 2.78271 19.9179 1.68644C18.5144 0.590168 16.7849 -0.00580053 15.0041 -0.00683459C13.2232 -0.00786865 11.493 0.586092 10.0883 1.68073C8.68353 2.77537 7.68475 4.30793 7.25055 6.03506C5.20238 6.22728 3.30697 7.20112 1.95804 8.75429C0.609105 10.3075 -0.0896671 12.3206 0.00686768 14.3755C0.103402 16.4304 0.987824 18.3691 2.47642 19.789C3.96502 21.2089 5.94338 22.0007 8.00055 22.0001H11.0005C11.2658 22.0001 11.5201 21.8947 11.7077 21.7072C11.8952 21.5196 12.0005 21.2653 12.0005 21.0001C12.0005 20.7348 11.8952 20.4805 11.7077 20.293C11.5201 20.1054 11.2658 20.0001 11.0005 20.0001H8.00055C7.21262 20.0024 6.43195 19.8494 5.70311 19.55C4.97428 19.2506 4.31156 18.8106 3.75278 18.2551C2.62428 17.1331 1.98769 15.6089 1.98305 14.0176C1.9784 12.4263 2.60609 10.8983 3.72803 9.7698C4.84997 8.6413 6.37425 8.00471 7.96555 8.00006C8.22214 8.01938 8.47676 7.9427 8.68001 7.7849C8.88326 7.6271 9.02066 7.39944 9.06555 7.14606C9.26917 5.71813 9.98113 4.41159 11.0707 3.4664C12.1602 2.52121 13.5542 2.00083 14.9965 2.00083C16.4389 2.00083 17.8329 2.52121 18.9224 3.4664C20.012 4.41159 20.7239 5.71813 20.9275 7.14606C20.9799 7.39064 21.1154 7.60947 21.3111 7.76522C21.5068 7.92097 21.7505 8.00397 22.0005 8.00006C23.5918 8.00006 25.118 8.63221 26.2432 9.75742C27.3684 10.8826 28.0005 12.4088 28.0005 14.0001C28.0005 15.5914 27.3684 17.1175 26.2432 18.2427C25.118 19.3679 23.5918 20.0001 22.0005 20.0001H19.0005C18.7353 20.0001 18.481 20.1054 18.2934 20.293C18.1059 20.4805 18.0005 20.7348 18.0005 21.0001C18.0005 21.2653 18.1059 21.5196 18.2934 21.7072C18.481 21.8947 18.7353 22.0001 19.0005 22.0001H22.0005C24.0427 21.9787 25.9993 21.1771 27.4695 19.7597C28.9397 18.3422 29.8121 16.4161 29.908 14.3761C30.0039 12.3361 29.316 10.3367 27.9852 8.78759C26.6545 7.23845 24.7817 6.2569 22.7505 6.04406Z" fill="#333333"/>
        <path d="M19.2944 14.707C19.483 14.8892 19.7356 14.99 19.9978 14.9877C20.26 14.9854 20.5108 14.8803 20.6962 14.6948C20.8816 14.5094 20.9868 14.2586 20.9891 13.9964C20.9913 13.7342 20.8905 13.4816 20.7084 13.293L15.7084 8.29303C15.5209 8.10556 15.2665 8.00024 15.0014 8.00024C14.7362 8.00024 14.4819 8.10556 14.2944 8.29303L9.29438 13.293C9.11223 13.4816 9.01143 13.7342 9.01371 13.9964C9.01599 14.2586 9.12116 14.5094 9.30656 14.6948C9.49197 14.8803 9.74279 14.9854 10.005 14.9877C10.2672 14.99 10.5198 14.8892 10.7084 14.707L14.0014 11.414V24C14.0014 24.2652 14.1067 24.5196 14.2943 24.7071C14.4818 24.8947 14.7362 25 15.0014 25C15.2666 25 15.521 24.8947 15.7085 24.7071C15.896 24.5196 16.0014 24.2652 16.0014 24V11.414L19.2944 14.707Z" fill="#333333"/>
        </svg>

      <input type="file" multiple (change)="onFileSelected($event)" #file  hidden/>
      <p class="mt-2">Drag files here or click <span class="text-primary" style="cursor: pointer;" (click)="file.click()">browser</span></p>
      <!-- <button type="submit" [disabled]="!file.valid">Upload</button> -->
    </div>
    <div
    *ngIf="files.length > 0 "
    class="upload-box d-flex align-items-center justify-content-between"
    >
<div class=" d-flex  align-items-start  justify-content-between">
  <svg width="39" height="44" viewBox="0 0 39 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.5 0C6.9875 0 5.75 1.2375 5.75 2.75V41.25C5.75 42.7625 6.9875 44 8.5 44H36C37.5125 44 38.75 42.7625 38.75 41.25V11L27.75 0H8.5Z" fill="#E2E5E7"/>
    <path d="M30.5 11H38.75L27.75 0V8.25C27.75 9.7625 28.9875 11 30.5 11Z" fill="#B0B7BD"/>
    <path d="M38.75 19.25L30.5 11H38.75V19.25Z" fill="#CAD1D8"/>
    <path d="M33.25 35.75C33.25 36.5063 32.6312 37.125 31.875 37.125H1.625C0.86875 37.125 0.25 36.5063 0.25 35.75V22C0.25 21.2437 0.86875 20.625 1.625 20.625H31.875C32.6312 20.625 33.25 21.2437 33.25 22V35.75Z" fill="#F15642"/>
    <path d="M6.24414 26.0522C6.24414 25.6892 6.53014 25.2932 6.99077 25.2932H9.53039C10.9604 25.2932 12.2474 26.2502 12.2474 28.0845C12.2474 29.8225 10.9604 30.7905 9.53039 30.7905H7.69477V32.2425C7.69477 32.7265 7.38677 33.0001 6.99077 33.0001C6.62777 33.0001 6.24414 32.7265 6.24414 32.2425V26.0522ZM7.69477 26.6778V29.4168H9.53039C10.2674 29.4168 10.8504 28.7665 10.8504 28.0845C10.8504 27.3158 10.2674 26.6778 9.53039 26.6778H7.69477ZM14.4006 33.0001C14.0376 33.0001 13.6416 32.8021 13.6416 32.3195V26.0742C13.6416 25.6796 14.0376 25.3922 14.4006 25.3922H16.9183C21.9425 25.3922 21.8325 33.0001 17.0173 33.0001H14.4006ZM15.0936 26.7342V31.6595H16.9183C19.8869 31.6595 20.0189 26.7342 16.9183 26.7342H15.0936ZM23.6145 26.8222V28.5698H26.4181C26.8141 28.5698 27.2101 28.9658 27.2101 29.3495C27.2101 29.7125 26.8141 30.0095 26.4181 30.0095H23.6145V32.3181C23.6145 32.7031 23.3409 32.9987 22.9559 32.9987C22.4719 32.9987 22.1763 32.7031 22.1763 32.3181V26.0728C22.1763 25.6782 22.4733 25.3908 22.9559 25.3908H26.8155C27.2995 25.3908 27.5855 25.6782 27.5855 26.0728C27.5855 26.4248 27.2995 26.8208 26.8155 26.8208H23.6145V26.8222Z" fill="white"/>
    <path d="M31.875 37.125H5.75V38.5H31.875C32.6313 38.5 33.25 37.8812 33.25 37.125V35.75C33.25 36.5063 32.6313 37.125 31.875 37.125Z" fill="#CAD1D8"/>
    </svg>
<span class="mx-2">
  <p class="mb-4 mt-2" style="font-size: 12px; line-height: 0;">{{files[0]?.name}}</p>
  <p  style="font-size: 13px !important; line-height: 0;color: #b6b6b6;text-align: right;">{{(files[0]?.size)/1024}} MB</p>
</span>
</div>
<span style="cursor: pointer;" (click)="files=[]">
  <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="8" transform="matrix(-1 0 0 1 40.5 0)" fill="#C13E33" fill-opacity="0.1"/>
    <path d="M21.9694 13.5C22.0632 13.5 22.1257 13.5625 22.1882 13.625L22.7819 14.5H18.2507L18.8444 13.625C18.8757 13.5625 18.9694 13.5 19.0632 13.5H21.9694ZM16.4382 14.5H16.0319H14.5007H14.2507C13.8444 14.5 13.5007 14.8438 13.5007 15.25C13.5007 15.6875 13.8444 16 14.2507 16H14.6257L15.3757 26.1562C15.4694 27.2188 16.3132 28 17.3757 28H23.6569C24.7194 28 25.5632 27.2188 25.6569 26.1562L26.4069 16H26.7507C27.1882 16 27.5007 15.6875 27.5007 15.25C27.5007 14.8438 27.1882 14.5 26.7507 14.5H26.5007H25.0007H24.5944L23.4382 12.7812C23.1257 12.3125 22.5632 12 21.9694 12H19.0632C18.4694 12 17.9069 12.3125 17.5944 12.7812L16.4382 14.5ZM16.1257 16H24.9069L24.1569 26.0625C24.1257 26.3125 23.9069 26.5 23.6569 26.5H17.3757C17.1257 26.5 16.9069 26.3125 16.8757 26.0625L16.1257 16Z" fill="#C13E33"/>
    </svg>

</span>


  </div>
   <button type="button" class="btn btn-primary mt-3" *ngIf="files?.length>0" (click)="upload()">Upload</button>
    </div>
  </div>
</div>
            <!-- <div class="col-lg-12 project" [innerHTML]="blog_details?.content"></div> -->
          </div>
  </div>
  </div>
</section>
